.App {
  text-align: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(5px + 2vmin);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Quote {
  font-size: 1.5rem;
  font-weight: 300;
  background-color: rgb(23, 7, 244);
  color: white;
  text-align: center;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 15%;
  bottom: 0%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
