.status-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  box-shadow: 0 2px 8px rgba(7, 7, 184, 0.25);
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 12px;
  background-color: white;
  font-size: medium;
  min-width: auto;
}

.status-item__title {
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  flex-flow: row;
  justify-content: flex-start;
  flex: 1;
}

.status-item h2 {
  color: #860e0e;
  font-size: 1rem;
  flex: 1;
  margin: 0 1rem;
  color: rgb(5, 0, 0);
}

.status-item__textbox {
  font-size: 0.8rem;
  font-weight: normal;
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  min-height: 8rem;
  text-align: wrap;
}

.status-item__button {
  font-size: 1rem;
  font-weight: normal;
  color: white;
  background-color: #0d6efd;
  border: 1px solid rgb(15, 15, 16);
  border-radius: 12px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}
