.output-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  box-shadow: 0 2px 8px rgba(7, 7, 184, 0.25);
  margin: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-radius: 12px;
  background-color: #d8f1ad;
  font-size: medium;
  min-width: auto;
}

.output-item__title {
  flex-direction: row;
  gap: 1rem 1rem 1rem 1rem;
  align-items: flex-start;
  flex-flow: row;
  justify-content: flex-start;
  flex: 1;
}

.output-item h2 {
  color: #860e0e;
  font-size: 1rem;
  flex: 1;
  align-content: left;
  min-width: auto;
  color: rgb(5, 0, 0);
}

.output-item__textbox {
  font-size: 1.5rem;
  font-weight: normal;
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 8px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  min-height: 16rem;
  min-width: auto;
  align-self: stretch;
  align-content: left;
  text-align: left;
  padding: 0.5rem;
}
.output-item__button {
  font-size: 1rem;
  font-weight: normal;
  color: white;
  background-color: #0d6efd;
  border: 1px solid rgb(15, 15, 16);
  border-radius: 12px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  align-content: left;
}

textarea {
  resize: none;
  width: max-content;
  min-width: max-content;
  background-color: transparent;
}

/* @media (min-width: auto) {
  textarea {
    resize: none;
    width: max-content;
    min-width: 70vw;
  }
  .output-item__textbox {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: left;
    font-size: 0.5rem;
    border-radius: 0.8rem;
    min-width: auto;
    margin: 0.5rem;
  }
} */
